html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  justify-content: center;
  line-height: 1;
  display: flex;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

*, :before, :after {
  box-sizing: border-box;
}

#MainContent img {
  justify-self: center;
  max-width: 90%;
  max-height: 50vh;
}

.flex {
  display: flex;
}

.fullheight {
  height: 100%;
}

.sb_sticky {
  position: sticky;
  top: 2em;
}

.row {
  box-sizing: border-box;
  border-bottom: 1px solid #0000;
}

.row > * {
  float: left;
  box-sizing: border-box;
}

.row:after, .row:before {
  content: "";
  clear: both;
  height: 0;
  display: block;
}

.row.uniform > * > :first-child {
  margin-top: 0;
}

.row.uniform > * > :last-child {
  margin-bottom: 0;
}

.row.\30 \% > * {
  padding: 0;
}

.row.\30 \% {
  margin: 0 0 -1px;
}

.row.uniform.\30 \% > * {
  padding: 0;
}

.row.uniform.\30 \% {
  margin: 0 0 -1px;
}

.row > * {
  padding: 40px 0 0 40px;
}

.row {
  margin: -40px 0 -1px -40px;
}

.row.uniform > * {
  padding: 40px 0 0 40px;
}

.row.uniform {
  margin: -40px 0 -1px -40px;
}

.row.\32 00\% > * {
  padding: 80px 0 0 80px;
}

.row.\32 00\% {
  margin: -80px 0 -1px -80px;
}

.row.uniform.\32 00\% > * {
  padding: 80px 0 0 80px;
}

.row.uniform.\32 00\% {
  margin: -80px 0 -1px -80px;
}

.row.\31 50\% > * {
  padding: 60px 0 0 60px;
}

.row.\31 50\% {
  margin: -60px 0 -1px -60px;
}

.row.uniform.\31 50\% > * {
  padding: 60px 0 0 60px;
}

.row.uniform.\31 50\% {
  margin: -60px 0 -1px -60px;
}

.row.\35 0\% > * {
  padding: 20px 0 0 20px;
}

.row.\35 0\% {
  margin: -20px 0 -1px -20px;
}

.row.uniform.\35 0\% > * {
  padding: 20px 0 0 20px;
}

.row.uniform.\35 0\% {
  margin: -20px 0 -1px -20px;
}

.row.\32 5\% > * {
  padding: 10px 0 0 10px;
}

.row.\32 5\% {
  margin: -10px 0 -1px -10px;
}

.row.uniform.\32 5\% > * {
  padding: 10px 0 0 10px;
}

.row.uniform.\32 5\% {
  margin: -10px 0 -1px -10px;
}

.\31 2u, .\31 2u\$ {
  clear: none;
  width: 100%;
  margin-left: 0;
}

.\31 1u, .\31 1u\$ {
  clear: none;
  width: 91.6667%;
  margin-left: 0;
}

.\31 0u, .\31 0u\$ {
  clear: none;
  width: 83.3333%;
  margin-left: 0;
}

.\39 u, .\39 u\$ {
  clear: none;
  width: 75%;
  margin-left: 0;
}

.\38 u, .\38 u\$ {
  clear: none;
  width: 66.6667%;
  margin-left: 0;
}

.\37 u, .\37 u\$ {
  clear: none;
  width: 58.3333%;
  margin-left: 0;
}

.\36 u, .\36 u\$ {
  clear: none;
  width: 50%;
  margin-left: 0;
}

.\35 u, .\35 u\$ {
  clear: none;
  width: 41.6667%;
  margin-left: 0;
}

.\34 u, .\34 u\$ {
  clear: none;
  width: 33.3333%;
  margin-left: 0;
}

.\33 u, .\33 u\$ {
  clear: none;
  width: 25%;
  margin-left: 0;
}

.\32 u, .\32 u\$ {
  clear: none;
  width: 16.6667%;
  margin-left: 0;
}

.\31 u, .\31 u\$ {
  clear: none;
  width: 8.33333%;
  margin-left: 0;
}

.\31 2u\$ + *, .\31 1u\$ + *, .\31 0u\$ + *, .\39 u\$ + *, .\38 u\$ + *, .\37 u\$ + *, .\36 u\$ + *, .\35 u\$ + *, .\34 u\$ + *, .\33 u\$ + *, .\32 u\$ + *, .\31 u\$ + * {
  clear: left;
}

.-\31 1u {
  margin-left: 91.6667%;
}

.-\31 0u {
  margin-left: 83.3333%;
}

.-\39 u {
  margin-left: 75%;
}

.-\38 u {
  margin-left: 66.6667%;
}

.-\37 u {
  margin-left: 58.3333%;
}

.-\36 u {
  margin-left: 50%;
}

.-\35 u {
  margin-left: 41.6667%;
}

.-\34 u {
  margin-left: 33.3333%;
}

.-\33 u {
  margin-left: 25%;
}

.-\32 u {
  margin-left: 16.6667%;
}

.-\31 u {
  margin-left: 8.33333%;
}

@media screen and (width <= 1680px) {
  .row > * {
    padding: 40px 0 0 40px;
  }

  .row {
    margin: -40px 0 -1px -40px;
  }

  .row.uniform > * {
    padding: 40px 0 0 40px;
  }

  .row.uniform {
    margin: -40px 0 -1px -40px;
  }

  .row.\32 00\% > * {
    padding: 80px 0 0 80px;
  }

  .row.\32 00\% {
    margin: -80px 0 -1px -80px;
  }

  .row.uniform.\32 00\% > * {
    padding: 80px 0 0 80px;
  }

  .row.uniform.\32 00\% {
    margin: -80px 0 -1px -80px;
  }

  .row.\31 50\% > * {
    padding: 60px 0 0 60px;
  }

  .row.\31 50\% {
    margin: -60px 0 -1px -60px;
  }

  .row.uniform.\31 50\% > * {
    padding: 60px 0 0 60px;
  }

  .row.uniform.\31 50\% {
    margin: -60px 0 -1px -60px;
  }

  .row.\35 0\% > * {
    padding: 20px 0 0 20px;
  }

  .row.\35 0\% {
    margin: -20px 0 -1px -20px;
  }

  .row.uniform.\35 0\% > * {
    padding: 20px 0 0 20px;
  }

  .row.uniform.\35 0\% {
    margin: -20px 0 -1px -20px;
  }

  .row.\32 5\% > * {
    padding: 10px 0 0 10px;
  }

  .row.\32 5\% {
    margin: -10px 0 -1px -10px;
  }

  .row.uniform.\32 5\% > * {
    padding: 10px 0 0 10px;
  }

  .row.uniform.\32 5\% {
    margin: -10px 0 -1px -10px;
  }

  .\31 2u\(xlarge\), .\31 2u\$\(xlarge\) {
    clear: none;
    width: 100%;
    margin-left: 0;
  }

  .\31 1u\(xlarge\), .\31 1u\$\(xlarge\) {
    clear: none;
    width: 91.6667%;
    margin-left: 0;
  }

  .\31 0u\(xlarge\), .\31 0u\$\(xlarge\) {
    clear: none;
    width: 83.3333%;
    margin-left: 0;
  }

  .\39 u\(xlarge\), .\39 u\$\(xlarge\) {
    clear: none;
    width: 75%;
    margin-left: 0;
  }

  .\38 u\(xlarge\), .\38 u\$\(xlarge\) {
    clear: none;
    width: 66.6667%;
    margin-left: 0;
  }

  .\37 u\(xlarge\), .\37 u\$\(xlarge\) {
    clear: none;
    width: 58.3333%;
    margin-left: 0;
  }

  .\36 u\(xlarge\), .\36 u\$\(xlarge\) {
    clear: none;
    width: 50%;
    margin-left: 0;
  }

  .\35 u\(xlarge\), .\35 u\$\(xlarge\) {
    clear: none;
    width: 41.6667%;
    margin-left: 0;
  }

  .\34 u\(xlarge\), .\34 u\$\(xlarge\) {
    clear: none;
    width: 33.3333%;
    margin-left: 0;
  }

  .\33 u\(xlarge\), .\33 u\$\(xlarge\) {
    clear: none;
    width: 25%;
    margin-left: 0;
  }

  .\32 u\(xlarge\), .\32 u\$\(xlarge\) {
    clear: none;
    width: 16.6667%;
    margin-left: 0;
  }

  .\31 u\(xlarge\), .\31 u\$\(xlarge\) {
    clear: none;
    width: 8.33333%;
    margin-left: 0;
  }

  .\31 2u\$\(xlarge\) + *, .\31 1u\$\(xlarge\) + *, .\31 0u\$\(xlarge\) + *, .\39 u\$\(xlarge\) + *, .\38 u\$\(xlarge\) + *, .\37 u\$\(xlarge\) + *, .\36 u\$\(xlarge\) + *, .\35 u\$\(xlarge\) + *, .\34 u\$\(xlarge\) + *, .\33 u\$\(xlarge\) + *, .\32 u\$\(xlarge\) + *, .\31 u\$\(xlarge\) + * {
    clear: left;
  }

  .-\31 1u\(xlarge\) {
    margin-left: 91.6667%;
  }

  .-\31 0u\(xlarge\) {
    margin-left: 83.3333%;
  }

  .-\39 u\(xlarge\) {
    margin-left: 75%;
  }

  .-\38 u\(xlarge\) {
    margin-left: 66.6667%;
  }

  .-\37 u\(xlarge\) {
    margin-left: 58.3333%;
  }

  .-\36 u\(xlarge\) {
    margin-left: 50%;
  }

  .-\35 u\(xlarge\) {
    margin-left: 41.6667%;
  }

  .-\34 u\(xlarge\) {
    margin-left: 33.3333%;
  }

  .-\33 u\(xlarge\) {
    margin-left: 25%;
  }

  .-\32 u\(xlarge\) {
    margin-left: 16.6667%;
  }

  .-\31 u\(xlarge\) {
    margin-left: 8.33333%;
  }
}

@media screen and (width <= 1280px) {
  .row > * {
    padding: 25px 0 0 25px;
  }

  .row {
    margin: -25px 0 -1px -25px;
  }

  .row.uniform > * {
    padding: 25px 0 0 25px;
  }

  .row.uniform {
    margin: -25px 0 -1px -25px;
  }

  .row.\32 00\% > * {
    padding: 50px 0 0 50px;
  }

  .row.\32 00\% {
    margin: -50px 0 -1px -50px;
  }

  .row.uniform.\32 00\% > * {
    padding: 50px 0 0 50px;
  }

  .row.uniform.\32 00\% {
    margin: -50px 0 -1px -50px;
  }

  .row.\31 50\% > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.\31 50\% {
    margin: -37.5px 0 -1px -37.5px;
  }

  .row.uniform.\31 50\% > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.uniform.\31 50\% {
    margin: -37.5px 0 -1px -37.5px;
  }

  .row.\35 0\% > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.\35 0\% {
    margin: -12.5px 0 -1px -12.5px;
  }

  .row.uniform.\35 0\% > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.uniform.\35 0\% {
    margin: -12.5px 0 -1px -12.5px;
  }

  .row.\32 5\% > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.\32 5\% {
    margin: -6.25px 0 -1px -6.25px;
  }

  .row.uniform.\32 5\% > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.uniform.\32 5\% {
    margin: -6.25px 0 -1px -6.25px;
  }

  .\31 2u\(large\), .\31 2u\$\(large\) {
    clear: none;
    width: 100%;
    margin-left: 0;
  }

  .\31 1u\(large\), .\31 1u\$\(large\) {
    clear: none;
    width: 91.6667%;
    margin-left: 0;
  }

  .\31 0u\(large\), .\31 0u\$\(large\) {
    clear: none;
    width: 83.3333%;
    margin-left: 0;
  }

  .\39 u\(large\), .\39 u\$\(large\) {
    clear: none;
    width: 75%;
    margin-left: 0;
  }

  .\38 u\(large\), .\38 u\$\(large\) {
    clear: none;
    width: 66.6667%;
    margin-left: 0;
  }

  .\37 u\(large\), .\37 u\$\(large\) {
    clear: none;
    width: 58.3333%;
    margin-left: 0;
  }

  .\36 u\(large\), .\36 u\$\(large\) {
    clear: none;
    width: 50%;
    margin-left: 0;
  }

  .\35 u\(large\), .\35 u\$\(large\) {
    clear: none;
    width: 41.6667%;
    margin-left: 0;
  }

  .\34 u\(large\), .\34 u\$\(large\) {
    clear: none;
    width: 33.3333%;
    margin-left: 0;
  }

  .\33 u\(large\), .\33 u\$\(large\) {
    clear: none;
    width: 25%;
    margin-left: 0;
  }

  .\32 u\(large\), .\32 u\$\(large\) {
    clear: none;
    width: 16.6667%;
    margin-left: 0;
  }

  .\31 u\(large\), .\31 u\$\(large\) {
    clear: none;
    width: 8.33333%;
    margin-left: 0;
  }

  .\31 2u\$\(large\) + *, .\31 1u\$\(large\) + *, .\31 0u\$\(large\) + *, .\39 u\$\(large\) + *, .\38 u\$\(large\) + *, .\37 u\$\(large\) + *, .\36 u\$\(large\) + *, .\35 u\$\(large\) + *, .\34 u\$\(large\) + *, .\33 u\$\(large\) + *, .\32 u\$\(large\) + *, .\31 u\$\(large\) + * {
    clear: left;
  }

  .-\31 1u\(large\) {
    margin-left: 91.6667%;
  }

  .-\31 0u\(large\) {
    margin-left: 83.3333%;
  }

  .-\39 u\(large\) {
    margin-left: 75%;
  }

  .-\38 u\(large\) {
    margin-left: 66.6667%;
  }

  .-\37 u\(large\) {
    margin-left: 58.3333%;
  }

  .-\36 u\(large\) {
    margin-left: 50%;
  }

  .-\35 u\(large\) {
    margin-left: 41.6667%;
  }

  .-\34 u\(large\) {
    margin-left: 33.3333%;
  }

  .-\33 u\(large\) {
    margin-left: 25%;
  }

  .-\32 u\(large\) {
    margin-left: 16.6667%;
  }

  .-\31 u\(large\) {
    margin-left: 8.33333%;
  }
}

@media screen and (width <= 980px) {
  .row > * {
    padding: 25px 0 0 25px;
  }

  .row {
    margin: -25px 0 -1px -25px;
  }

  .row.uniform > * {
    padding: 25px 0 0 25px;
  }

  .row.uniform {
    margin: -25px 0 -1px -25px;
  }

  .row.\32 00\% > * {
    padding: 50px 0 0 50px;
  }

  .row.\32 00\% {
    margin: -50px 0 -1px -50px;
  }

  .row.uniform.\32 00\% > * {
    padding: 50px 0 0 50px;
  }

  .row.uniform.\32 00\% {
    margin: -50px 0 -1px -50px;
  }

  .row.\31 50\% > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.\31 50\% {
    margin: -37.5px 0 -1px -37.5px;
  }

  .row.uniform.\31 50\% > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.uniform.\31 50\% {
    margin: -37.5px 0 -1px -37.5px;
  }

  .row.\35 0\% > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.\35 0\% {
    margin: -12.5px 0 -1px -12.5px;
  }

  .row.uniform.\35 0\% > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.uniform.\35 0\% {
    margin: -12.5px 0 -1px -12.5px;
  }

  .row.\32 5\% > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.\32 5\% {
    margin: -6.25px 0 -1px -6.25px;
  }

  .row.uniform.\32 5\% > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.uniform.\32 5\% {
    margin: -6.25px 0 -1px -6.25px;
  }

  .\31 2u\(medium\), .\31 2u\$\(medium\) {
    clear: none;
    width: 100%;
    margin-left: 0;
  }

  .\31 1u\(medium\), .\31 1u\$\(medium\) {
    clear: none;
    width: 91.6667%;
    margin-left: 0;
  }

  .\31 0u\(medium\), .\31 0u\$\(medium\) {
    clear: none;
    width: 83.3333%;
    margin-left: 0;
  }

  .\39 u\(medium\), .\39 u\$\(medium\) {
    clear: none;
    width: 75%;
    margin-left: 0;
  }

  .\38 u\(medium\), .\38 u\$\(medium\) {
    clear: none;
    width: 66.6667%;
    margin-left: 0;
  }

  .\37 u\(medium\), .\37 u\$\(medium\) {
    clear: none;
    width: 58.3333%;
    margin-left: 0;
  }

  .\36 u\(medium\), .\36 u\$\(medium\) {
    clear: none;
    width: 50%;
    margin-left: 0;
  }

  .\35 u\(medium\), .\35 u\$\(medium\) {
    clear: none;
    width: 41.6667%;
    margin-left: 0;
  }

  .\34 u\(medium\), .\34 u\$\(medium\) {
    clear: none;
    width: 33.3333%;
    margin-left: 0;
  }

  .\33 u\(medium\), .\33 u\$\(medium\) {
    clear: none;
    width: 25%;
    margin-left: 0;
  }

  .\32 u\(medium\), .\32 u\$\(medium\) {
    clear: none;
    width: 16.6667%;
    margin-left: 0;
  }

  .\31 u\(medium\), .\31 u\$\(medium\) {
    clear: none;
    width: 8.33333%;
    margin-left: 0;
  }

  .\31 2u\$\(medium\) + *, .\31 1u\$\(medium\) + *, .\31 0u\$\(medium\) + *, .\39 u\$\(medium\) + *, .\38 u\$\(medium\) + *, .\37 u\$\(medium\) + *, .\36 u\$\(medium\) + *, .\35 u\$\(medium\) + *, .\34 u\$\(medium\) + *, .\33 u\$\(medium\) + *, .\32 u\$\(medium\) + *, .\31 u\$\(medium\) + * {
    clear: left;
  }

  .-\31 1u\(medium\) {
    margin-left: 91.6667%;
  }

  .-\31 0u\(medium\) {
    margin-left: 83.3333%;
  }

  .-\39 u\(medium\) {
    margin-left: 75%;
  }

  .-\38 u\(medium\) {
    margin-left: 66.6667%;
  }

  .-\37 u\(medium\) {
    margin-left: 58.3333%;
  }

  .-\36 u\(medium\) {
    margin-left: 50%;
  }

  .-\35 u\(medium\) {
    margin-left: 41.6667%;
  }

  .-\34 u\(medium\) {
    margin-left: 33.3333%;
  }

  .-\33 u\(medium\) {
    margin-left: 25%;
  }

  .-\32 u\(medium\) {
    margin-left: 16.6667%;
  }

  .-\31 u\(medium\) {
    margin-left: 8.33333%;
  }
}

@media screen and (width <= 736px) {
  .row > * {
    padding: 20px 0 0 20px;
  }

  .row {
    margin: -20px 0 -1px -20px;
  }

  .row.uniform > * {
    padding: 20px 0 0 20px;
  }

  .row.uniform {
    margin: -20px 0 -1px -20px;
  }

  .row.\32 00\% > * {
    padding: 40px 0 0 40px;
  }

  .row.\32 00\% {
    margin: -40px 0 -1px -40px;
  }

  .row.uniform.\32 00\% > * {
    padding: 40px 0 0 40px;
  }

  .row.uniform.\32 00\% {
    margin: -40px 0 -1px -40px;
  }

  .row.\31 50\% > * {
    padding: 30px 0 0 30px;
  }

  .row.\31 50\% {
    margin: -30px 0 -1px -30px;
  }

  .row.uniform.\31 50\% > * {
    padding: 30px 0 0 30px;
  }

  .row.uniform.\31 50\% {
    margin: -30px 0 -1px -30px;
  }

  .row.\35 0\% > * {
    padding: 10px 0 0 10px;
  }

  .row.\35 0\% {
    margin: -10px 0 -1px -10px;
  }

  .row.uniform.\35 0\% > * {
    padding: 10px 0 0 10px;
  }

  .row.uniform.\35 0\% {
    margin: -10px 0 -1px -10px;
  }

  .row.\32 5\% > * {
    padding: 5px 0 0 5px;
  }

  .row.\32 5\% {
    margin: -5px 0 -1px -5px;
  }

  .row.uniform.\32 5\% > * {
    padding: 5px 0 0 5px;
  }

  .row.uniform.\32 5\% {
    margin: -5px 0 -1px -5px;
  }

  .\31 2u\(small\), .\31 2u\$\(small\) {
    clear: none;
    width: 100%;
    margin-left: 0;
  }

  .\31 1u\(small\), .\31 1u\$\(small\) {
    clear: none;
    width: 91.6667%;
    margin-left: 0;
  }

  .\31 0u\(small\), .\31 0u\$\(small\) {
    clear: none;
    width: 83.3333%;
    margin-left: 0;
  }

  .\39 u\(small\), .\39 u\$\(small\) {
    clear: none;
    width: 75%;
    margin-left: 0;
  }

  .\38 u\(small\), .\38 u\$\(small\) {
    clear: none;
    width: 66.6667%;
    margin-left: 0;
  }

  .\37 u\(small\), .\37 u\$\(small\) {
    clear: none;
    width: 58.3333%;
    margin-left: 0;
  }

  .\36 u\(small\), .\36 u\$\(small\) {
    clear: none;
    width: 50%;
    margin-left: 0;
  }

  .\35 u\(small\), .\35 u\$\(small\) {
    clear: none;
    width: 41.6667%;
    margin-left: 0;
  }

  .\34 u\(small\), .\34 u\$\(small\) {
    clear: none;
    width: 33.3333%;
    margin-left: 0;
  }

  .\33 u\(small\), .\33 u\$\(small\) {
    clear: none;
    width: 25%;
    margin-left: 0;
  }

  .\32 u\(small\), .\32 u\$\(small\) {
    clear: none;
    width: 16.6667%;
    margin-left: 0;
  }

  .\31 u\(small\), .\31 u\$\(small\) {
    clear: none;
    width: 8.33333%;
    margin-left: 0;
  }

  .\31 2u\$\(small\) + *, .\31 1u\$\(small\) + *, .\31 0u\$\(small\) + *, .\39 u\$\(small\) + *, .\38 u\$\(small\) + *, .\37 u\$\(small\) + *, .\36 u\$\(small\) + *, .\35 u\$\(small\) + *, .\34 u\$\(small\) + *, .\33 u\$\(small\) + *, .\32 u\$\(small\) + *, .\31 u\$\(small\) + * {
    clear: left;
  }

  .-\31 1u\(small\) {
    margin-left: 91.6667%;
  }

  .-\31 0u\(small\) {
    margin-left: 83.3333%;
  }

  .-\39 u\(small\) {
    margin-left: 75%;
  }

  .-\38 u\(small\) {
    margin-left: 66.6667%;
  }

  .-\37 u\(small\) {
    margin-left: 58.3333%;
  }

  .-\36 u\(small\) {
    margin-left: 50%;
  }

  .-\35 u\(small\) {
    margin-left: 41.6667%;
  }

  .-\34 u\(small\) {
    margin-left: 33.3333%;
  }

  .-\33 u\(small\) {
    margin-left: 25%;
  }

  .-\32 u\(small\) {
    margin-left: 16.6667%;
  }

  .-\31 u\(small\) {
    margin-left: 8.33333%;
  }
}

@-ms-viewport {
  width: device-width;
}

body {
  background-image: -webkit-linear-gradient(top, #0000000d, #0000);
  background-image: -ms-linear-gradient(top, #0000000d, #0000);
  background-image: linear-gradient(top, #0000000d, #0000);
  color: #696969;
  background-image: -moz-linear-gradient(top, #0000000d, #0000);
  background-color: #f7f7f7;
  background-position: 0 0, 0 0;
  background-repeat: repeat-x, repeat;
  background-size: 100% 30em, auto;
  font-family: Open Sans, sans-serif;
  font-size: 14pt;
  font-weight: 500;
  line-height: 2.25em;
}

body.is-loading * {
  -ms-animation: none !important;
  transition: none !important;
  animation: none !important;
}

input, select, textarea {
  color: #696969;
  font-family: Open Sans, sans-serif;
  font-size: 14pt;
  font-weight: 500;
  line-height: 2.25em;
}

a {
  color: #027ac0;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
  font-weight: 800;
}

h2, h3, h4, h5, h6 {
  padding-top: .5em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

h1 {
  margin: 0 0 .5em;
  font-size: 2.25em;
}

h2 {
  margin: 0 0 1.1em;
  font-size: 1.75em;
}

h3 {
  margin: 0 0 1em;
  font-size: 1.35em;
}

@media (orientation: portrait) {
  h1 {
    margin: 0 0 .5em;
    font-size: 2em;
  }

  h2 {
    margin: 0 0 1.1em;
    font-size: 1.75em;
  }

  h3 {
    margin: 0 0 1em;
    font-size: 1.5em;
  }
}

b, strong {
  color: #444;
  font-weight: 600;
}

i, em {
  font-style: italic;
}

br.clear {
  clear: both;
}

sub {
  font-size: .8em;
  position: relative;
  top: .5em;
}

sup {
  font-size: .8em;
  position: relative;
  top: -.5em;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

blockquote {
  border-left: .5em solid #eee;
  padding: 1em 0 1em 2em;
  font-style: italic;
}

p, ul, ol, dl, table {
  margin-bottom: 2em;
}

section, article {
  margin-bottom: 2.5em;
}

section > :last-child, article > :last-child, section:last-child, article:last-child {
  margin-bottom: 0;
}

header h2, header h3 {
  margin: 0 0 .25em;
}

header p {
  margin: 0;
}

form label {
  color: #444;
  margin: 0 0 1em;
  font-size: .8em;
  font-weight: 800;
  display: block;
}

form input[type="text"], form input[type="email"], form input[type="password"], form select, form textarea {
  -webkit-appearance: none;
  border: 1px solid #eee;
  border-radius: 8px;
  width: 100%;
  display: block;
}

form input[type="text"]:focus, form input[type="email"]:focus, form input[type="password"]:focus, form select:focus, form textarea:focus {
  border-color: #0090c5;
}

form input[type="text"], form input[type="email"], form input[type="password"], form select {
  padding: 0 1em;
  line-height: 3em;
}

form textarea {
  min-height: 9em;
  padding: 1em;
}

form .formerize-placeholder {
  color: #555 !important;
}

form ::-webkit-input-placeholder {
  color: #555 !important;
}

form :-moz-placeholder {
  color: #555 !important;
}

form ::-moz-placeholder {
  color: #555 !important;
}

form :-ms-input-placeholder {
  color: #555 !important;
}

form ::-moz-focus-inner {
  border: 0;
}

table, table.default {
  width: 100%;
}

table.default tr {
  border-top: 1px solid #eee;
}

table.default tr:first-child {
  border-top: 0;
}

table.default td {
  padding: .5em 1em;
}

table.default th {
  text-align: left;
  margin: 0 0 1em;
  padding: .5em 1em;
  font-weight: 800;
}

table.default thead {
  color: #fff;
  background: #404248;
}

.image {
  outline: 0;
  display: inline-block;
}

.image img {
  border-radius: 2em;
  width: 100%;
  display: block;
}

.image.fit {
  width: 100%;
  display: block;
}

.image.featured {
  width: 100%;
  margin: 0 0 2.5em;
  display: block;
}

.image.left {
  float: left;
  margin: 0 2em 2em 0;
}

.image.centered {
  margin: 0 0 2.5em;
  display: block;
}

.image.centered img {
  width: auto;
  margin: 0 auto;
}

input[type="button"], input[type="submit"], input[type="reset"], button, .button {
  -webkit-appearance: none;
  color: #fff;
  cursor: pointer;
  background: #0090c5;
  border: 0;
  border-radius: 6px;
  outline: 0;
  padding: .6em 1.5em;
  font-size: 1.35em;
  font-weight: 800;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
  display: inline-block;
  position: relative;
}

input[type="button"].icon:before, input[type="submit"].icon:before, input[type="reset"].icon:before, button.icon:before, .button.icon:before {
  margin-right: .5em;
  position: relative;
  top: .05em;
}

input[type="button"]:hover, input[type="submit"]:hover, input[type="reset"]:hover, button:hover, .button:hover {
  background: #10a0d5;
}

input[type="button"]:active, input[type="submit"]:active, input[type="reset"]:active, button:active, .button:active {
  background: #20b0e5;
}

input[type="button"].alt, input[type="submit"].alt, input[type="reset"].alt, button.alt, .button.alt {
  color: #444;
  background: #0000000d;
}

input[type="button"].alt:hover, input[type="submit"].alt:hover, input[type="reset"].alt:hover, button.alt:hover, .button.alt:hover {
  background: #00000016;
}

input[type="button"].alt:active, input[type="submit"].alt:active, input[type="reset"].alt:active, button.alt:active, .button.alt:active {
  background: #00000026;
}

input[type="button"].big, input[type="submit"].big, input[type="reset"].big, button.big, .button.big {
  padding: .8em .9em;
  font-size: 2.25em;
}

#content ul {
  padding-left: 3em;
  list-style: disc;
}

ul.default {
  padding-left: 1em;
  list-style: disc;
}

ul.default li {
  padding-left: .5em;
}

ul.actions li {
  margin: 0 0 0 .25em;
  padding: 0 0 0 .25em;
  display: inline-block;
}

ul.actions li:first-child {
  margin-left: 0;
  padding-left: 0;
}

ul.menu li {
  border-left: 1px solid #e0e0e0;
  margin: 0 0 0 .75em;
  padding: 0 0 0 .75em;
  display: inline-block;
}

ul.menu li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

ol.default {
  padding-left: 1.25em;
  list-style: decimal;
}

ol.default li {
  padding-left: .25em;
}

.box {
  background: #fff;
  border-radius: 2em;
  padding: 2em;
  box-shadow: 0 3px #0000000d;
}

.box.feature {
  padding: 0;
}

.box.feature .inner {
  padding: 4em 2em 3em;
}

.box.feature h2 {
  font-size: 1.35em;
}

.box.feature p {
  margin: 0;
}

.box.feature .image {
  margin: 0;
  position: relative;
}

.box.feature .image img {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.icon {
  text-decoration: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

.widget.thumbnails .grid {
  margin: 0 0 3em;
}

.widget.thumbnails .image {
  margin: 0;
}

.widget.contact ul {
  margin-bottom: 1.5em;
  position: relative;
  top: 4px;
  left: -4px;
  overflow: hidden;
}

.widget.contact ul li {
  margin-right: .25em;
  display: inline-block;
}

.widget.contact ul li a {
  text-align: center;
  opacity: .75;
  background: #222;
  border-radius: .35em;
  outline: 0;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
  display: inline-block;
}

.widget.contact ul li a:before {
  color: #f3f3f3;
  font-size: 1.75em;
  line-height: 1.5em;
}

.widget.contact ul li a:hover {
  background: #444;
}

.widget.contact ul li a:active {
  background: #666;
}

#main-wrapper {
  background: #fff;
  border-radius: 2em;
  margin: 0 1em 1em;
  padding: 5em;
  box-shadow: 0 3px #0000003d;
}

@media screen and (width <= 1680px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}

@media screen and (width <= 1280px) {
  body, input, select, textarea {
    font-size: 11pt;
  }
}

#navPanel, #navToggle {
  display: none;
}

@media screen and (width <= 980px) {
  body, input, select, textarea {
    font-size: 11pt;
  }

  section, article {
    clear: both;
    margin-bottom: 3em !important;
  }

  section.last, article.last {
    margin-bottom: 0 !important;
  }

  .image.left {
    width: 25%;
    margin-bottom: 2em !important;
  }

  ul.menu li {
    display: block;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.actions li {
    margin: 1em 0 0;
    padding: 0;
    display: block;
  }

  ul.actions li:first-child {
    margin-top: 0;
  }

  input[type="button"], input[type="submit"], input[type="reset"], button, .button {
    text-align: center;
    width: 100%;
    padding: .85em 0;
    font-size: 1.35em;
  }

  input[type="button"].icon, input[type="submit"].icon, input[type="reset"].icon, button.icon, .button.icon {
    padding-right: .75em !important;
  }

  input[type="button"].icon:before, input[type="submit"].icon:before, input[type="reset"].icon:before, button.icon:before, .button.icon:before {
    margin-right: .5em;
    top: .05em;
  }

  #content {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 4em;
    margin-bottom: 0 !important;
  }

  #content > :last-child {
    margin-bottom: 0 !important;
  }
}

@media screen and (width <= 736px) {
  input[type="button"].big, input[type="submit"].big, input[type="reset"].big, button.big, .button.big {
    padding: .8em .9em;
    font-size: 1.5em;
  }

  body, input, select, textarea {
    line-height: 2em;
  }

  h2, h3, h4, h5, h6 {
    margin: 0 0 1em;
    font-size: 14pt;
  }

  section, article {
    clear: both;
    margin-bottom: 3em !important;
  }

  section.last, article.last {
    margin-bottom: 0 !important;
  }

  .image.left {
    width: 25%;
    margin-bottom: 2em !important;
  }

  ul.menu li {
    display: block;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.actions li {
    margin: 1em 0 0;
    padding: 0;
    display: block;
  }

  ul.actions li:first-child {
    margin-top: 0;
  }

  input[type="button"], input[type="submit"], input[type="reset"], button, .button {
    text-align: center;
    width: 100%;
    padding: .65em 0;
    font-size: 1.25em;
  }

  input[type="button"].icon, input[type="submit"].icon, input[type="reset"].icon, button.icon, .button.icon {
    padding-right: .75em;
  }

  input[type="button"].icon:before, input[type="submit"].icon:before, input[type="reset"].icon:before, button.icon:before, .button.icon:before {
    margin-right: .5em;
    top: .05em;
  }

  .box {
    margin: 0;
    padding: 15px;
  }

  .box.feature {
    padding: 0;
    margin: 20px 0 0 !important;
  }

  .box.feature .inner {
    padding: 30px 15px;
  }

  .box.feature p {
    margin: 0;
  }

  .box.feature .image {
    margin: 0;
    position: relative;
  }

  .box.feature .image img {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .widget.thumbnails .image {
    margin: 0;
  }

  .widget.thumbnails .x {
    padding: 1em .5em 0 0;
  }

  .widget.thumbnails .y {
    padding: 1em 0 0 .5em;
  }

  .widget.thumbnails .row:first-child .x, .widget.thumbnails .row:first-child .y {
    padding-top: 0;
  }

  #content {
    padding-bottom: 3em;
  }
}

/*# sourceMappingURL=main.css.map */
