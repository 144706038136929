/* Reset */

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
		display: flex;
		justify-content: center;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Grid */
	#MainContent img {
		max-width: 90%;
		max-height: 50vh;
		justify-self: center;
	}
	.flex {
		display: flex;;
	}
	.fullheight {
		height: 100%;
	}
	.sb_sticky {
		position: sticky;
		top: 2em;
	}

	.row {
		border-bottom: solid 1px transparent;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row > * {
		float: left;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row:after, .row:before {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}

	.row.uniform > * > :first-child {
		margin-top: 0;
	}

	.row.uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row.uniform.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.uniform.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row > * {
		padding: 40px 0 0 40px;
	}

	.row {
		margin: -40px 0 -1px -40px;
	}

	.row.uniform > * {
		padding: 40px 0 0 40px;
	}

	.row.uniform {
		margin: -40px 0 -1px -40px;
	}

	.row.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.uniform.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.uniform.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.uniform.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.uniform.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.uniform.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.uniform.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.row.uniform.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.uniform.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.\31 2u, .\31 2u\24 {
		width: 100%;
		clear: none;
		margin-left: 0;
	}

	.\31 1u, .\31 1u\24 {
		width: 91.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 0u, .\31 0u\24 {
		width: 83.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\39 u, .\39 u\24 {
		width: 75%;
		clear: none;
		margin-left: 0;
	}

	.\38 u, .\38 u\24 {
		width: 66.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\37 u, .\37 u\24 {
		width: 58.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\36 u, .\36 u\24 {
		width: 50%;
		clear: none;
		margin-left: 0;
	}

	.\35 u, .\35 u\24 {
		width: 41.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\34 u, .\34 u\24 {
		width: 33.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\33 u, .\33 u\24 {
		width: 25%;
		clear: none;
		margin-left: 0;
	}

	.\32 u, .\32 u\24 {
		width: 16.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 u, .\31 u\24 {
		width: 8.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\31 2u\24 + *,
	.\31 1u\24 + *,
	.\31 0u\24 + *,
	.\39 u\24 + *,
	.\38 u\24 + *,
	.\37 u\24 + *,
	.\36 u\24 + *,
	.\35 u\24 + *,
	.\34 u\24 + *,
	.\33 u\24 + *,
	.\32 u\24 + *,
	.\31 u\24 + * {
		clear: left;
	}

	.\-11u {
		margin-left: 91.66667%;
	}

	.\-10u {
		margin-left: 83.33333%;
	}

	.\-9u {
		margin-left: 75%;
	}

	.\-8u {
		margin-left: 66.66667%;
	}

	.\-7u {
		margin-left: 58.33333%;
	}

	.\-6u {
		margin-left: 50%;
	}

	.\-5u {
		margin-left: 41.66667%;
	}

	.\-4u {
		margin-left: 33.33333%;
	}

	.\-3u {
		margin-left: 25%;
	}

	.\-2u {
		margin-left: 16.66667%;
	}

	.\-1u {
		margin-left: 8.33333%;
	}

	@media screen and (max-width: 1680px) {

		.row > * {
			padding: 40px 0 0 40px;
		}

		.row {
			margin: -40px 0 -1px -40px;
		}

		.row.uniform > * {
			padding: 40px 0 0 40px;
		}

		.row.uniform {
			margin: -40px 0 -1px -40px;
		}

		.row.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 80px 0 0 80px;
		}

		.row.uniform.\32 00\25 {
			margin: -80px 0 -1px -80px;
		}

		.row.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 60px 0 0 60px;
		}

		.row.uniform.\31 50\25 {
			margin: -60px 0 -1px -60px;
		}

		.row.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 20px 0 0 20px;
		}

		.row.uniform.\35 0\25 {
			margin: -20px 0 -1px -20px;
		}

		.row.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.uniform.\32 5\25 {
			margin: -10px 0 -1px -10px;
		}

		.\31 2u\28xlarge\29, .\31 2u\24\28xlarge\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28xlarge\29, .\31 1u\24\28xlarge\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28xlarge\29, .\31 0u\24\28xlarge\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28xlarge\29, .\39 u\24\28xlarge\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28xlarge\29, .\38 u\24\28xlarge\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28xlarge\29, .\37 u\24\28xlarge\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28xlarge\29, .\36 u\24\28xlarge\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28xlarge\29, .\35 u\24\28xlarge\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28xlarge\29, .\34 u\24\28xlarge\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28xlarge\29, .\33 u\24\28xlarge\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28xlarge\29, .\32 u\24\28xlarge\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28xlarge\29, .\31 u\24\28xlarge\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28xlarge\29 + *,
		.\31 1u\24\28xlarge\29 + *,
		.\31 0u\24\28xlarge\29 + *,
		.\39 u\24\28xlarge\29 + *,
		.\38 u\24\28xlarge\29 + *,
		.\37 u\24\28xlarge\29 + *,
		.\36 u\24\28xlarge\29 + *,
		.\35 u\24\28xlarge\29 + *,
		.\34 u\24\28xlarge\29 + *,
		.\33 u\24\28xlarge\29 + *,
		.\32 u\24\28xlarge\29 + *,
		.\31 u\24\28xlarge\29 + * {
			clear: left;
		}

		.\-11u\28xlarge\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28xlarge\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28xlarge\29 {
			margin-left: 75%;
		}

		.\-8u\28xlarge\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28xlarge\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28xlarge\29 {
			margin-left: 50%;
		}

		.\-5u\28xlarge\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28xlarge\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28xlarge\29 {
			margin-left: 25%;
		}

		.\-2u\28xlarge\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28xlarge\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 1280px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28large\29, .\31 2u\24\28large\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28large\29, .\31 1u\24\28large\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28large\29, .\31 0u\24\28large\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28large\29, .\39 u\24\28large\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28large\29, .\38 u\24\28large\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28large\29, .\37 u\24\28large\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28large\29, .\36 u\24\28large\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28large\29, .\35 u\24\28large\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28large\29, .\34 u\24\28large\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28large\29, .\33 u\24\28large\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28large\29, .\32 u\24\28large\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28large\29, .\31 u\24\28large\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28large\29 + *,
		.\31 1u\24\28large\29 + *,
		.\31 0u\24\28large\29 + *,
		.\39 u\24\28large\29 + *,
		.\38 u\24\28large\29 + *,
		.\37 u\24\28large\29 + *,
		.\36 u\24\28large\29 + *,
		.\35 u\24\28large\29 + *,
		.\34 u\24\28large\29 + *,
		.\33 u\24\28large\29 + *,
		.\32 u\24\28large\29 + *,
		.\31 u\24\28large\29 + * {
			clear: left;
		}

		.\-11u\28large\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28large\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28large\29 {
			margin-left: 75%;
		}

		.\-8u\28large\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28large\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28large\29 {
			margin-left: 50%;
		}

		.\-5u\28large\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28large\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28large\29 {
			margin-left: 25%;
		}

		.\-2u\28large\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28large\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 980px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28medium\29, .\31 2u\24\28medium\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28medium\29, .\31 1u\24\28medium\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28medium\29, .\31 0u\24\28medium\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28medium\29, .\39 u\24\28medium\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28medium\29, .\38 u\24\28medium\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28medium\29, .\37 u\24\28medium\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28medium\29, .\36 u\24\28medium\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28medium\29, .\35 u\24\28medium\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28medium\29, .\34 u\24\28medium\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28medium\29, .\33 u\24\28medium\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28medium\29, .\32 u\24\28medium\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28medium\29, .\31 u\24\28medium\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28medium\29 + *,
		.\31 1u\24\28medium\29 + *,
		.\31 0u\24\28medium\29 + *,
		.\39 u\24\28medium\29 + *,
		.\38 u\24\28medium\29 + *,
		.\37 u\24\28medium\29 + *,
		.\36 u\24\28medium\29 + *,
		.\35 u\24\28medium\29 + *,
		.\34 u\24\28medium\29 + *,
		.\33 u\24\28medium\29 + *,
		.\32 u\24\28medium\29 + *,
		.\31 u\24\28medium\29 + * {
			clear: left;
		}

		.\-11u\28medium\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28medium\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28medium\29 {
			margin-left: 75%;
		}

		.\-8u\28medium\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28medium\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28medium\29 {
			margin-left: 50%;
		}

		.\-5u\28medium\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28medium\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28medium\29 {
			margin-left: 25%;
		}

		.\-2u\28medium\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28medium\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 736px) {

		.row > * {
			padding: 20px 0 0 20px;
		}

		.row {
			margin: -20px 0 -1px -20px;
		}

		.row.uniform > * {
			padding: 20px 0 0 20px;
		}

		.row.uniform {
			margin: -20px 0 -1px -20px;
		}

		.row.\32 00\25 > * {
			padding: 40px 0 0 40px;
		}

		.row.\32 00\25 {
			margin: -40px 0 -1px -40px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 40px 0 0 40px;
		}

		.row.uniform.\32 00\25 {
			margin: -40px 0 -1px -40px;
		}

		.row.\31 50\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.\31 50\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.uniform.\31 50\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.\35 0\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.\35 0\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 10px 0 0 10px;
		}

		.row.uniform.\35 0\25 {
			margin: -10px 0 -1px -10px;
		}

		.row.\32 5\25 > * {
			padding: 5px 0 0 5px;
		}

		.row.\32 5\25 {
			margin: -5px 0 -1px -5px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 5px 0 0 5px;
		}

		.row.uniform.\32 5\25 {
			margin: -5px 0 -1px -5px;
		}

		.\31 2u\28small\29, .\31 2u\24\28small\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28small\29, .\31 1u\24\28small\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28small\29, .\31 0u\24\28small\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28small\29, .\39 u\24\28small\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28small\29, .\38 u\24\28small\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28small\29, .\37 u\24\28small\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28small\29, .\36 u\24\28small\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28small\29, .\35 u\24\28small\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28small\29, .\34 u\24\28small\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28small\29, .\33 u\24\28small\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28small\29, .\32 u\24\28small\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28small\29, .\31 u\24\28small\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28small\29 + *,
		.\31 1u\24\28small\29 + *,
		.\31 0u\24\28small\29 + *,
		.\39 u\24\28small\29 + *,
		.\38 u\24\28small\29 + *,
		.\37 u\24\28small\29 + *,
		.\36 u\24\28small\29 + *,
		.\35 u\24\28small\29 + *,
		.\34 u\24\28small\29 + *,
		.\33 u\24\28small\29 + *,
		.\32 u\24\28small\29 + *,
		.\31 u\24\28small\29 + * {
			clear: left;
		}

		.\-11u\28small\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28small\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28small\29 {
			margin-left: 75%;
		}

		.\-8u\28small\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28small\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28small\29 {
			margin-left: 50%;
		}

		.\-5u\28small\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28small\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28small\29 {
			margin-left: 25%;
		}

		.\-2u\28small\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28small\29 {
			margin-left: 8.33333%;
		}

	}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
		background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
		background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
		background-image: linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
		background-color: #f7f7f7;
		background-position: top left, top left;
		background-repeat: repeat-x, repeat;
		background-size: 100% 30em, auto;
		font-family: 'Open Sans', sans-serif;
		font-size: 14pt;
		color: #696969;
		font-weight: 500;
		line-height: 2.25em;
	}

		body.is-loading * {
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
		}

	input, select, textarea {
		font-family: 'Open Sans', sans-serif;
		font-size: 14pt;
		color: #696969;
		font-weight: 500;
		line-height: 2.25em;
	}

	a {
		color: #027AC0;
		text-decoration: underline;
	}

		a:hover {
			text-decoration: none;
		}

	h1, h2, h3, h4, h5, h6 {
		color: #444;
		font-weight: 800;
	}

	h2, h3, h4, h5, h6 {
		padding-top: 0.5em;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
			outline: 0;
		}

	h1 {
		font-size: 2.25em;
		margin: 0 0 0.5em 0;
	}

	h2 {
		font-size: 1.75em;
		margin: 0 0 1.1em 0;
	}

	h3 {
		font-size: 1.35em;
		margin: 0 0 1em 0;
	}

	@media (orientation: portrait) {
		h1 {
			font-size: 2.0em;
			margin: 0 0 0.5em 0;
		}
	
		h2 {
			font-size: 1.75em;
			margin: 0 0 1.1em 0;
		}
	
		h3 {
			font-size: 1.5em;
			margin: 0 0 1em 0;
		}
	}

	b, strong {
		font-weight: 600;
		color: #444;
	}

	i, em {
		font-style: italic;
	}

	br.clear {
		clear: both;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	hr {
		border: 0;
		border-top: solid 1px #eee;
	}

	blockquote {
		border-left: solid 0.5em #eee;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	p, ul, ol, dl, table {
		margin-bottom: 2em;
	}

/* Section/Article */

	section, article {
		margin-bottom: 2.5em;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}
	header h2, header h3 {
		margin: 0 0 0.25em 0;
	}

	header p {
		margin: 0;
	}

/* Forms */

	form label {
		display: block;
		font-weight: 800;
		margin: 0 0 1em 0;
		font-size: 0.8em;
		color: #444;
	}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select,
	form textarea {
		-webkit-appearance: none;
		display: block;
		width: 100%;
		border-radius: 8px;
		border: solid 1px #eee;
	}

		form input[type="text"]:focus,
		form input[type="email"]:focus,
		form input[type="password"]:focus,
		form select:focus,
		form textarea:focus {
			border-color: #0090c5;
		}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"] {
		line-height: 3em;
		padding: 0 1em;
	}

	form select {
		line-height: 3em;
		padding: 0 1em;
	}

	form textarea {
		min-height: 9em;
		padding: 1em;
	}

	form .formerize-placeholder {
		color: #555 !important;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Tables */

	table {
		width: 100%;
	}

		table.default {
			width: 100%;
		}

			table.default tr {
				border-top: solid 1px #eee;
			}

				table.default tr:first-child {
					border-top: 0;
				}

			table.default td {
				padding: 0.5em 1em 0.5em 1em;
			}

			table.default th {
				text-align: left;
				padding: 0.5em 1em 0.5em 1em;
				font-weight: 800;
				margin: 0 0 1em 0;
			}

			table.default thead {
				background: #404248;
				color: #fff;
			}

/* Image */

	.image {
		display: inline-block;
		outline: 0;
	}

		.image img {
			display: block;
			width: 100%;
			border-radius: 2em;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 2.5em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 2.5em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		-moz-transition: background-color .25s ease-in-out;
		-webkit-transition: background-color .25s ease-in-out;
		-ms-transition: background-color .25s ease-in-out;
		transition: background-color .25s ease-in-out;
		-webkit-appearance: none;
		position: relative;
		display: inline-block;
		background: #0090c5;
		color: #fff;
		text-decoration: none;
		border-radius: 6px;
		font-weight: 800;
		outline: 0;
		border: 0;
		cursor: pointer;
		font-size: 1.35em;
		padding: 0.6em 1.5em;
	}

		input[type="button"].icon:before,
		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		button.icon:before,
		.button.icon:before {
			position: relative;
			margin-right: 0.5em;
			top: 0.05em;
		}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			background: #10a0d5;
		}

		input[type="button"]:active,
		input[type="submit"]:active,
		input[type="reset"]:active,
		button:active,
		.button:active {
			background: #20b0e5;
		}

		input[type="button"].alt,
		input[type="submit"].alt,
		input[type="reset"].alt,
		button.alt,
		.button.alt {
			background: #f1f1f1;
			background: rgba(0, 0, 0, 0.05);
			color: #444;
		}

			input[type="button"].alt:hover,
			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			button.alt:hover,
			.button.alt:hover {
				background: #e8e8e8;
				background: rgba(0, 0, 0, 0.085);
			}

			input[type="button"].alt:active,
			input[type="submit"].alt:active,
			input[type="reset"].alt:active,
			button.alt:active,
			.button.alt:active {
				background: #e4e4e4;
				background: rgba(0, 0, 0, 0.15);
			}

		input[type="button"].big,
		input[type="submit"].big,
		input[type="reset"].big,
		button.big,
		.button.big {
			font-size: 2.25em;
			padding: 0.8em 0.9em;
		}

/* List */

	#content ul {
		list-style: disc;
		padding-left: 3em;		
	}

	ul.default {
		list-style: disc;
		padding-left: 1em;
	}

		ul.default li {
			padding-left: 0.5em;
		}

	ul.actions li {
		display: inline-block;
		padding: 0 0 0 0.25em;
		margin: 0 0 0 0.25em;
	}

		ul.actions li:first-child {
			margin-left: 0;
			padding-left: 0;
		}

	ul.menu li {
		border-left: solid 1px #e0e0e0;
		display: inline-block;
		padding: 0 0 0 0.75em;
		margin: 0 0 0 0.75em;
	}

		ul.menu li:first-child {
			border-left: 0;
			margin-left: 0;
			padding-left: 0;
		}

	ol.default {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol.default li {
			padding-left: 0.25em;
		}

/* Box */

	.box {
		background: #fff;
		border-radius: 2em;
		box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
		padding: 2em;
	}

		.box.feature {
			padding: 0;
		}

			.box.feature .inner {
				padding: 4em 2em 3em 2em;
			}

			.box.feature h2 {
				font-size: 1.35em;
			}

			.box.feature p {
				margin: 0;
			}

			.box.feature .image {
				position: relative;
				margin: 0;
			}

				.box.feature .image img {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

/* Icons */

	.icon {
		position: relative;
		text-decoration: none;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-transform: none !important;
		}

		.icon > .label {
			display: none;
		}

/* Widgets */

	.widget.thumbnails .grid {
		margin: 0 0 3em 0;
	}

	.widget.thumbnails .image {
		margin: 0;
	}

	.widget.contact ul {
		overflow: hidden;
		position: relative;
		left: -4px;
		top: 4px;
		margin-bottom: 1.5em;
	}

		.widget.contact ul li {
			display: inline-block;
			margin-right: 0.25em;
		}

			.widget.contact ul li a {
				-moz-transition: background-color .25s ease-in-out;
				-webkit-transition: background-color .25s ease-in-out;
				-ms-transition: background-color .25s ease-in-out;
				transition: background-color .25s ease-in-out;
				display: inline-block;
				width: 2.5em;
				height: 2.5em;
				text-align: center;
				line-height: 2.5em;
				border-radius: 0.35em;
				outline: 0;
				opacity: 0.75;
				text-decoration: none;
				background: #222222;
			}

				.widget.contact ul li a:before {
					color: #f3f3f3;
					font-size: 1.75em;
					line-height: 1.5em;
				}

				.widget.contact ul li a:hover {
					background: #444444;
				}

				.widget.contact ul li a:active {
					background: #666666;
				}

/* Wrappers */

	#main-wrapper {
		background: rgba(255, 255, 255, 1.0);
		border-radius: 2em;
		box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.24);
		padding: 5em;
		margin: 1em;
		margin-top: 0;
	}


/* XLarge */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 12pt;
			}

	}

/* Large */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
			}

	}

/* Medium */

	#navPanel, #navToggle {
		display: none;
	}

	@media screen and (max-width: 980px) {

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* Section/Article */

			section, article {
				clear: both;
				margin-bottom: 3em !important;
			}

			section.last, article.last {
				margin-bottom: 0 !important;
			}

		/* Image */

			.image.left {
				width: 25%;
				margin-bottom: 2em !important;
			}

		/* List */

			ul.menu li {
				display: block;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
			}

			ul.actions li {
				display: block;
				padding: 0;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				width: 100%;
				text-align: center;
				font-size: 1.35em;
				padding: 0.85em 0;
			}

				input[type="button"].icon,
				input[type="submit"].icon,
				input[type="reset"].icon,
				button.icon,
				.button.icon {
					padding-right: 0.75em !important;
				}

					input[type="button"].icon:before,
					input[type="submit"].icon:before,
					input[type="reset"].icon:before,
					button.icon:before,
					.button.icon:before {
						margin-right: 0.5em;
						top: 0.05em;
					}

		/* Content */

			#content {
				border-bottom: solid 1px #e8e8e8;
				padding-bottom: 4em;
				margin-bottom: 0 !important;
			}

				#content > :last-child {
					margin-bottom: 0 !important;
				}
	}

/* Small */

	@media screen and (max-width: 736px) {

		input[type="button"].big,
		input[type="submit"].big,
		input[type="reset"].big,
		button.big,
		.button.big {
			font-size: 1.5em;
			padding: 0.8em 0.9em;
		}

		/* Basic */

			body, input, select, textarea {
				line-height: 2em;
			}

			h2, h3, h4, h5, h6 {
				font-size: 14pt;
				margin: 0 0 1em 0;
			}

		/* Section/Article */

			section, article {
				clear: both;
				margin-bottom: 3em !important;
			}

				section.last, article.last {
					margin-bottom: 0 !important;
				}

		/* Image */

			.image.left {
				width: 25%;
				margin-bottom: 2em !important;
			}

		/* List */

			ul.menu li {
				display: block;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
			}

			ul.actions li {
				display: block;
				padding: 0;
				margin: 1em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				width: 100%;
				text-align: center;
				font-size: 1.25em;
				padding: 0.65em 0;
			}

				input[type="button"].icon,
				input[type="submit"].icon,
				input[type="reset"].icon,
				button.icon,
				.button.icon {
					padding-right: 0.75em;
				}

					input[type="button"].icon:before,
					input[type="submit"].icon:before,
					input[type="reset"].icon:before,
					button.icon:before,
					.button.icon:before {
						margin-right: 0.5em;
						top: 0.05em;
					}

		/* Box */
			.box {
				padding: 15px 15px 15px 15px;
				margin: 0;
			}

				.box.feature {
					margin: 20px 0 0 0 !important;
					padding: 0;
				}

					.box.feature .inner {
						padding: 30px 15px 30px 15px;
					}

					.box.feature p {
						margin: 0;
					}

					.box.feature .image {
						position: relative;
						margin: 0;
					}

						.box.feature .image img {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
						}

		/* Widgets */

			.widget.thumbnails .image {
				margin: 0;
			}

			.widget.thumbnails .x {
				padding: 1em 0.5em 0 0;
			}

			.widget.thumbnails .y {
				padding: 1em 0 0 0.5em;
			}

			.widget.thumbnails .row:first-child .x, .widget.thumbnails .row:first-child .y {
				padding-top: 0;
			}


		/* Content */

			#content {
				padding-bottom: 3em;
			}

	}